<template>
    <div>
        <div class="vx-row mb-4">
            <div class="vx-col w-full">
                <label class="vs-input--label">Zona</label>
                <v-select
                    class="vs-custom w-full"
                    name="region_id"
                    label="text"
                    v-validate="ruleRequired"
                    v-model.lazy="currentRegion.zone_id"
                    :options="zonesList"
                    :reduce="text => text.value"
                    :clearable="false"
                    :searchable="true"
                    placeholder="Seleccione una opción">
                    <div slot="no-options">No hay coincidencias</div>
                </v-select>
				<v-error v-if="hasError('region_id')" :error="getErrorText('region_id')" />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <vs-input
                    class="w-full mb-4"
                    name="name"
                    label="Nombre de región *"
                    placeholder="Requerido"
                    v-validate="ruleRequired"
                    v-model.lazy="currentRegion.name"
                    icon-pack="feather"
                    :danger="hasError('name')"
                    :danger-text="getErrorText('name')"
                    :success="isSuccessful('name')"
                    v-on="{ input: e => { currentRegion.name = upper(e) } }"
                />
            </div>
        </div>
        <div class="vx-col w-full">
                <label class="vs-input--label">Usuarios *</label>
                <v-select
                    class="vs-custom w-full"
                    name="user_id"
                    label="text"
                    v-model.lazy="currentRegion.region_manager.user_id"
                    :options="userList"
                    :reduce="text => text.value"
                    :clearable="true"
                    :searchable="true"
                    placeholder="Seleccione una opción">
                    <div slot="no-options">No hay coincidencias</div>
                </v-select>
				<v-error v-if="hasError('users')" :error="getErrorText('users')" />
            </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <vs-alert v-if="errorMessage" color="danger">
                    <span>{{ errorMessage }}</span>
                </vs-alert>
            </div>
        </div>
        <div class="vx-row my-5">
            <div class="vx-col w-1/2">
                <vs-button class="w-full" @click="handleSave()" color="dark">Guardar</vs-button>
            </div>
            <div class="vx-col w-1/2">
                <vs-button class="w-full" @click="handleClose()" type="border" color="dark">Cerrar</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";

export default {
    name: 'CreateRegionControl',
    mixins: [
        inputHelper
    ],
    props: {
        currentRegion: { required: true, type: Object },
        zonesList: { required: true, type: Array },
        userList: { required: true, type: Array }
    },
    data() {
        return {
            ruleRequired: 'required',
            errorMessage: null,
            user_id: null
        }
    },
    methods: {
        async handleSave() {
            this.showLoading(true);
            try {
                this.errorMessage = null;

                const validity = await this.$validator.validateAll();
                if(!validity) {
                    this.showLoading(false);
                    return;
                }

                const {data} = await axios.post(`api/kof/post/region`, this.currentRegion);
                if(!data.success) {
                    this.errors.clear();
                    const errors = data.message;
                    const errorKeys = Object.keys(errors);
                    for (const key of errorKeys)
                        this.errors.add({ field: key, msg: errors[key][0] });
                    this.showLoading(false);
                    return;
                }

                this.$emit('on-save');
                this.showLoading(false);
            }
            catch (error) {
                console.log(error);
                this.errorMessage = 'Ha ocurrido un error al guardar el usuario. Por favor inténtalo de nuevo.';
            }
            this.showLoading(false);
        },
        handleClose() {
            this.$emit('on-close');
        },
        hasError(control) {
            return this.errors.has(control);
        },
        getErrorText(control) {
            return this.errors.first(control);
        },
        isSuccessful(control) {
            const field = this.fields[control];
            return (field && field.required && field.valid && !this.hasError(control));
        },
        clear() {
            this.errors.clear();
            this.errorMessage = null;
        }
    }
}
</script>

<style>

</style>
<template>
    <div id="kof-tools">
        <div class="vx-row">

            <div class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg-black h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                    Catálogo de <span>zonas</span> y <span>regiones</span>.
                    </h1>
                </vx-card>
            </div>

            <div class="vx-col w-full mb-6">
                <vx-card title="Gestiona zonas y regiones">
                    <p class="mb-4">En este apartado podrás administrar las zonas y regiones dentro de tu programa</p>
                </vx-card>
            </div>

            <div class="vx-col w-full mb-6">
                <vx-card title="Zonas">
                    <vs-table ref="zonesTable" stripe search noDataText="No se han generado registros" :data="zonesList" pagination :max-items="zonesPerPage">
                        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between mt-4 ml-4 mb-4">
                            <vs-button class="w-1/3" color="dark" @click="isCreateZoneModalOpened = true,getUsersList('zone_managers', true),zoneModalTitle='Crear zona'">Crear zona</vs-button>

                            <vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
                                <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">
                                        {{ zonesPage * zonesPerPage - (zonesPerPage - 1) }} - 
                                        {{ zonesList.length - zonesPage * zonesPerPage > 0 ? zonesPage * zonesPerPage : zonesList.length }} de {{ queriedZonesItems }}
                                    </span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>
                                    <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="zonesPerPage = it" :key="it">
                                        <span>{{it}}</span>
                                    </vs-dropdown-item>            
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>

                    <template slot="thead">
						<vs-th class="bold" sort-key="id">ID_ZONA</vs-th>
						<vs-th class="bold" sort-key="name">ZONA</vs-th>
						<vs-th class="bold">GERENTE DE ZONA</vs-th>
						<vs-th class="bold">ACCIONES</vs-th>
					</template>

                    <template slot-scope="{data}">
						<vs-tr :key="index" v-for="(tableRow, index) in data">
							<vs-td :data="data[index].id">
								{{ data[index].id }}
							</vs-td>

							<vs-td :data="data[index].name">
								{{ data[index].name }}
							</vs-td>

							<vs-td>
                                {{ data[index].zone_manager ? getFullName(data[index].zone_manager.user.personal) : 'Sin asignar' }}
							</vs-td>

							<vs-td :data="data[index].id">
                                <vx-tooltip
                                    title="Editar zona."
                                    color="dark"
                                    style="float:left;cursor:pointer;margin-top:-8px"
                                    class="mt-1"
                                    text="Edita la información básica de la zona.">
                                    <vs-button @click.stop="openZoneViewModal(data[index]), zoneModalTitle='Editar zona'" type="flat" class="rgred" icon="edit"></vs-button>
                                </vx-tooltip>
                                <vx-tooltip
                                    title="Eliminar zona."
                                    color="dark"
                                    style="float:left;cursor:pointer;margin-top:-8px"
                                    class="mt-1"
                                    text="Elimina la zona seleccionada.">
                                    <vs-button @click.stop="handleRemoveZone(data[index])" type="flat" class="rgred" icon="delete"></vs-button>
                                </vx-tooltip>
								<!-- <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="openZoneViewModal(data[index])" />
                                <feather-icon icon="ToggleRightIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-8" @click.stop="handleRemoveZone(data[index])" /> -->
							</vs-td>
						</vs-tr>
					</template>

                    </vs-table>
                </vx-card>
            </div>

            <div class="vx-col w-full mb-6">
                <vx-card title="Regiones">
                    <vs-table ref="regionsTable" search stripe noDataText="No se han generado registros" :data="regionsList" pagination :max-items="regionsPerPage">
                        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between mt-4 ml-4 mb-4">
                            <vs-button class="w-1/3" color="dark" @click="isCreateRegionModalOpened = true, getUsersList('region_managers', true),regionModalTitle='Crear región'">Crear región</vs-button>

                            <vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
                                <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">
                                        {{ regionsPage * regionsPerPage - (regionsPerPage - 1) }} - 
                                        {{ regionsList.length - regionsPage * regionsPerPage > 0 ? regionsPage * regionsPerPage : regionsList.length }} de {{ queriedRegionsItems }}
                                    </span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>
                                    <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="regionsPerPage = it" :key="it">
                                        <span>{{it}}</span>
                                    </vs-dropdown-item>            
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>

                    <template slot="thead">
						<vs-th class="bold" sort-key="id">ID_REGIÓN</vs-th>
						<vs-th class="bold" sort-key="name">REGIÓN</vs-th>
						<vs-th class="bold">GERENTE DE REGIÓN</vs-th>
						<vs-th class="bold">ZONA</vs-th>
						<vs-th class="bold">ACCIONES</vs-th>
					</template>

                    <template slot-scope="{data}">
						<vs-tr :key="index" v-for="(tableRow, index) in data">
							<vs-td :data="data[index].id">
								{{ data[index].id }}
							</vs-td>

							<vs-td :data="data[index].name">
								{{ data[index].name }}
							</vs-td>

							<vs-td>
								{{ data[index].region_manager ? getFullName(data[index].region_manager.user.personal) : 'Sin asignar' }}
							</vs-td>

							<vs-td>
								{{ data[index].zone ? data[index].zone.name : 'Sin asignar' }}
							</vs-td>

							<vs-td :data="data[index].id">
                                <vx-tooltip
                                    title="Editar región."
                                    color="dark"
                                    style="float:left;cursor:pointer;margin-top:-8px"
                                    class="mt-1"
                                    text="Edita la información básica de la región.">
                                    <vs-button @click.stop="openRegionViewModal(data[index]), regionModalTitle='Editar región'" type="flat" class="rgred" icon="edit"></vs-button>
                                </vx-tooltip>
                                <vx-tooltip
                                    title="Eliminar región."
                                    color="dark"
                                    style="float:left;cursor:pointer;margin-top:-8px"
                                    class="mt-1"
                                    text="Elimina la región seleccionada.">
                                    <vs-button @click.stop="handleRemoveRegion(data[index])" type="flat" class="rgred" icon="delete"></vs-button>
                                </vx-tooltip>
								<!-- <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="openRegionViewModal(data[index])" /> -->
                                <!-- <feather-icon icon="ToggleRightIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-8" @click.stop="handleRemoveRegion(data[index])" /> -->
							</vs-td>

                            <template slot="expand">
                                <div  v-if="data[index].region_manager" class="w-full">
                                    <vs-table ref="subregionsTable" search stripe noDataText="No se han generado registros" :data="data[index].region_manager.subregion_managers" pagination max-items="25">
                                        <div slot="header">
                                            <h4 class="mb-2 ml-2">Líderes de venta.</h4>
                                            <vs-button class="mb-2" color="dark" @click="isCreateSubregionModalOpened = true, getUsersList('subregion_managers',true), currentRegion = data[index]">Agregar líder de venta</vs-button>
                                        </div>

                                    <template slot="thead">
                                        <vs-th class="bold" sort-key="id">LIDER ID</vs-th>
                                        <vs-th class="bold" sort-key="name">LÍDER DE VENTA</vs-th>
                                        <vs-th class="bold" sort-key="name">CONTACTO</vs-th>
                                        <vs-th class="bold" sort-key="name">ACCIONES</vs-th>
                                    </template>

                                    <template slot-scope="{data}">
                                        <vs-tr :key="index" v-for="(tableRowSub, index) in data">
                                            <vs-td :data="data[index].id">
                                                <strong>{{ data[index].id }}</strong>
                                            </vs-td>

                                            <vs-td :data="getFullName(data[index].user.id)">
                                                <strong>{{ getFullName(data[index].user.personal) }}</strong>
                                            </vs-td>

                                            <vs-td :data="getFullName(data[index].user.personal)">
                                                {{data[index].user.email}}
                                                <br> tel. {{data[index].user.personal.phone}}
                                            </vs-td>
                                            <vs-td>
                                                <vx-tooltip
                                                    title="Editar líder de venta."
                                                    color="dark"
                                                    style="float:left;cursor:pointer;margin-top:-8px"
                                                    class="mt-1"
                                                    text="Edita el líder de venta seleccionado.">
                                                    <vs-button @click.stop="openSubregionViewModel(data[index], tableRow)" type="flat" class="rgred" icon="edit"></vs-button>
                                                </vx-tooltip>
                                            </vs-td>
                                        </vs-tr>
                                    </template>

                                    </vs-table>
                                </div>
                            </template>
						</vs-tr>
					</template>
                    </vs-table>
                </vx-card>
            </div>
        </div>

        <vs-popup :active.sync="isCreateRegionModalOpened" :title="regionModalTitle">
            <create-region-control
                v-if="userList.length > 0"
                :userList="getUserListOptions"
                ref="createRegionControl"
                :currentRegion="currentRegion"
                :zonesList="getZonesListOptions"
                @on-save="handleOnSaveRegion()"
                @on-close="isCreateRegionModalOpened = false"
            />
        </vs-popup>

        <vs-popup :active.sync="isCreateZoneModalOpened" :title="zoneModalTitle">
            <create-zone-control
                v-if="userList.length > 0"
                ref="createZoneControl"
                :currentZone="currentZone"
                :regionsList="getRegionsListOptions"
                :userList="getUserListOptions"
                @on-save="handleOnSaveZone()"
                @on-close="isCreateZoneModalOpened = false, currentZone = {
                    zone_manager: {
                        user_id: null
                    }
                }"
            />
        </vs-popup>
        
        <vs-popup :active.sync="isCreateSubregionModalOpened" :title="subregionModalTitle">
            <create-subregion-manager-control
                :currentSubregionManager="currentSubregionManager"
                :user-list="getUserListOptions"
                :currentRegion="currentRegion"
                @on-save="handleOnSaveSubregion()"
                @on-close="isCreateSubregionModalOpened = false"
            ></create-subregion-manager-control>
        </vs-popup>

    </div>
</template>

<script>
import CreateRegionControl from "./modals/CreateRegionControl.vue";
import CreateZoneControl from "./modals/CreateZoneControl.vue";
import CreateSubregionManagerControl from "./modals/CreateSubregionManagerControl.vue";

export default {
    name: 'RegionsCatalog',
    components: { 
        CreateRegionControl,
        CreateZoneControl,
        CreateSubregionManagerControl
    },
    data() {
        return {
            regionsList: [],
            zonesList: [],
            userList: [],
            regionsPerPage: 5,
            zonesPerPage: 5,
            currentRegion: {},
            currentZone: {
                zone_manager: {
                    user_id: null
                }
            },
            currentSubregionManager: {},
			itemsPerPageDefinitions: [5, 10, 25, 50],
            isMounted: false,
            isCreateRegionModalOpened: false,
            isCreateZoneModalOpened: false,
            isCreateSubregionModalOpened: false,
            regionModalTitle: "Crear región",
            zoneModalTitle: "Crear zona",
            subregionModalTitle: "Crear líder de venta"
        }
    },
    async mounted() {
        this.showLoading(true);
        await this.getRegionsList();
        await this.getZonesList();
        // await this.getUsersList();
        this.isMounted = true;
        this.showLoading(false);
    },
    watch: {
        isCreateRegionModalOpened(newValue) {
            if(!newValue) {
                this.currentRegion = {};
                this.$refs.createRegionControl.clear();
            }
        },
        isCreateZoneModalOpened(newValue) {
            if(!newValue) {
                this.currentZone = {};
                this.$refs.createZoneControl.clear();
            }
        }
    },
    methods: {
        async handleRemoveRegion(currentItem) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Eliminar región',
                text: `¿Estás seguro que deseas ELIMINAR la región ${currentItem.name}?`,
                acceptText: "Aceptar",
                cancelText: 'Cancelar',
                accept: () => this.removeRegion(currentItem)
            });
        },
        async removeRegion(currentItem) {
            try {
                this.showLoading(true);
                const {data} = await axios.post(`api/kof/post/delete-region`, currentItem);
                if(data.success) {
                    await this.getRegionsList();
                    await this.getZonesList();
                    this.showLoading(false);
                    return;
                }
                this.errorNotif('Esta región cuenta con líderes de venta asociados, por lo tanto no es posible eliminarse');
            }
            catch (error) {
                this.showLoading(false);
                console.log(error);
                this.warn('Ha ocurrido un error');
            }
            
        },
        async handleRemoveZone(currentItem) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Eliminar zona',
                text: `¿Estás seguro que deseas ELIMINAR la zona ${currentItem.name}?`,
                acceptText: "Aceptar",
                cancelText: 'Cancelar',
                accept: () => this.removeZone(currentItem)
            });
        },
        async removeZone(currentItem) {
            try {
                const {data} = await axios.post(`api/kof/post/delete-zone`, currentItem);
                if(data.success) {
                    await this.getRegionsList();
                    await this.getZonesList();
                    return;
                }
                this.errorNotif('Esta región cuenta con Regiones asignadas, por lo tanto no es posible eliminarse');
            }
            catch (error) {
                console.log(error);
                this.warn('Ha ocurrido un error');
            }
        },
        handleOnSaveRegion() {
            this.isCreateRegionModalOpened = false;
            this.getRegionsList();
        },
        handleOnSaveZone() {
            this.currentZone = {
                zone_manager: {
                    user_id: null
                }
            };
            this.isCreateZoneModalOpened = false;
            this.getZonesList();
        },
        handleOnSaveSubregion() {
            this.isCreateSubregionModalOpened = false;
            this.getRegionsList();
        },
        async getRegionsList() {
            try {
                const {data} = await axios.get(`api/kof/get/regions?programId=${this.currentProgramData.id}`);
                if(data.success) {
                    this.regionsList = data.message;
                    return;
                }
                // console.log(data.message);
            }
            catch (error) {
                console.log(error);
            }
            this.warn('Ha ocurrido un error');
        },
        async getZonesList() {
            try {
                const {data} = await axios.get(`api/kof/get/zones?programId=${this.currentProgramData.id}`);
                if(data.success) {
                    this.zonesList = data.message;
                    return;
                }
                // console.log(data.message);
            }
            catch (error) {
                console.log(error);
            }
            this.warn('Ha ocurrido un error');
        },
        async getUsersList(role, isNew = false) {
            try {
                this.showLoading(true);
                this.userList = [];
                if(isNew){
                    this.currentZone = {
                        zone_manager: {
                            user_id: null 
                        }
                    }
                    this.currentRegion = {
                        region_manager: {
                            user_id: null
                        }
                    }
                    this.currentSubregionManager = {
                        user_id: null
                    }
                }
                const {data} = await axios.get(`api/get/v1/forms/pgm-users/list?role=${role}`);
                if(data.length > 0) {
                    this.userList = data;
                    this.showLoading(false);
                    return;
                }
                // console.log(data);
            }
            catch (error) {
                console.log(error);
            }
            this.showLoading(false);
            this.warn('Ha ocurrido un error');
        },
        openRegionViewModal(currentItem) {
            this.currentRegion = currentItem;
            this.getUsersList("region_managers");
            if(this.currentRegion.region_manager == null || this.currentRegion.region_manager_id == 0){
                this.currentRegion.region_manager = {
                    user_id: null
                }
            }
            this.isCreateRegionModalOpened = true;
        },
        openZoneViewModal(currentItem) {
            this.getUsersList("zone_managers");
            this.currentZone = currentItem;
            if(this.currentZone.zone_manager == null || this.currentZone.zone_manager == 0){
                this.currentZone.zone_manager = {
                    user_id: null
                }
            }
            this.isCreateZoneModalOpened = true;
        },
        openSubregionViewModel(currentItem, currentRegion) {
            this.getUsersList("subregion_managers");
            this.currentRegion = currentRegion;
            this.currentSubregionManager = currentItem;
            this.isCreateSubregionModalOpened = true;
        },
        getFullName(currentItem) {
            let fullName = currentItem.first_name;

            if(currentItem.second_name)
                fullName += ` ${currentItem.second_name}`;

            fullName += ` ${currentItem.last_name_1}`;

            if(currentItem.last_name_2)
                fullName += ` ${currentItem.last_name_2}`;

            return fullName;
        },
    },
    computed: {
        regionsPage () {
			if (this.isMounted)
				return this.$refs.regionsTable.currentx;
			return 0;
		},
        queriedRegionsItems () {
			return this.$refs.regionsTable ? this.$refs.regionsTable.queriedResults.length : this.regionsList.length
		},
        zonesPage () {
			if (this.isMounted)
				return this.$refs.zonesTable.currentx;
			return 0;
		},
        queriedZonesItems () {
			return this.$refs.zonesTable ? this.$refs.zonesTable.queriedResults.length : this.zonesList.length
		},
        getRegionsListOptions() {
            return this.regionsList.map((region) => ({ value: region.id, text: region.name }));
        },
        getZonesListOptions() {
            return this.zonesList.map((zone) => ({ value: zone.id, text: zone.name }));
        },
        getUserListOptions() {
            if(this.userList.length >= 1){
                return this.userList.map((user) => ({ value: user.id, text: user.select_name }));
            } else {
                return [];
            }
        }
    }
}
</script>

<style>

</style>